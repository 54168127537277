import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EPrivileges } from "../../../shared/oversight-core/enums/privileges";
import { RootState } from "../../store";

type AuthState = {
  token: string | null;
  isNewUser: boolean;
  mobileNumber: string;
  privileges: EPrivileges[];
};

const token = localStorage.getItem("token");

const initialState: AuthState = {
  token: null,
  isNewUser: false,
  mobileNumber: "",
  privileges: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState: { ...initialState, token },
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setPrivileges: (state, action: PayloadAction<EPrivileges[]>) => {
      state.privileges = action.payload;
    },
    setIsNewUser: (
      state,
      action: PayloadAction<Omit<AuthState, "token" | "privileges">>
    ) => {
      state.isNewUser = action.payload.isNewUser;
      state.mobileNumber = action.payload.mobileNumber;
    },
  },
});

export const { setToken, setIsNewUser, setPrivileges } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
