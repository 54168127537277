import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useLazyGetNotificationsQuery,
  useLazyGetUnreadNotificationsCountQuery,
} from "../../../redux/api/notification/notificationAPI";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import {
  selectBillingSpaceFilter,
  setBillingSpaceFilter,
} from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { setSelectedFilterSpace } from "../../../redux/features/filter/filter-slice";
import { countUnreadNotifications } from "../../../redux/features/notification/notification-slice";
import { setScheduleFiltersSpaceAndSpaceDetails } from "../../../redux/features/schedule-filter/schedule-filter-slice";
import { setSelectedSmartControllerFilterSpace } from "../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import { setUsageFilterSelectedSpace } from "../../../redux/features/usage-filter/usage-filter-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import { consumerPrivateRoutes } from "../../../screens/routes";
import oversightLogo from "../../oversight-core/assets/images/oversight_logo.svg";
import oversightLogoC from "../../oversight-core/assets/images/oversight_logo_c.svg";
import { EDeviceStatus } from "../../oversight-core/enums/device-status";
import { AppRoute } from "../../oversight-core/interfaces/app-routes";
import { ISpaceCluster } from "../../oversight-core/interfaces/space-cluster";
import MobileNavBar from "../../oversight-core/shared-components/mobile-nav-bar/mobile-nav-bar";
import NotificationsPanel from "../../oversight-core/shared-components/notifications-panel/notifications-panel";
import SideBar from "../../oversight-core/shared-components/sidebar/side-bar";
import AppSelect from "../../oversight-core/ui-elements/app-select/app-select";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import ScrollToTop from "../../oversight-core/ui-elements/scroll-to-top/ScrollToTop";
import ErrorBoundary from "../../oversight-core/utils/ErrorBoundary";
import styles from "./dashboard-layout.module.scss";

const defaultBillingSpaceFilter: ISpaceCluster = {
  id: "",
  label: "",
  serviceProviderAccount: { label: "", accountNumber: "", tariffCode: "" },
  rootSpace: {
    id: "",
    name: "",
    parentSpaceId: "",
    powerConsumers: [],
    childSpaces: [],
    clusterId: "",
    tariffCode: "",
    accountNumber: "",
    accountNumberLabel: "",
    smartDevices: [],
    powerState: EDeviceStatus.OFF,
  },
};

enum BillingSpaceExcludedPages {
  SOLAR = "SOLAR",
  PROFILE = "PROFILE",
  USAGE_GUIDE = "USAGE GUIDE",
  SETTINGS = "SETTINGS",
  ADD_CONTROLLER = "SMART CONTROLLERS > ADD CONTROLLER",
  ADD_SCHEDULE = "ADD SCHEDULE",
  EDIT_SCHEDULE = "EDIT SCHEDULE",
}

enum ScrollingExcludedPages {
  DEVICES = "DEVICES",
  CONTROLLERS = "CONTROLLERS",
  SCHEDULE = "SCHEDULE",
  SOLAR = "SOLAR",
}

const DashboardLayout = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const profile = useSelector(selectProfile);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [selectedSpaceCluster, setSelectedSpaceCluster] =
    useState<ISpaceCluster>({ ...defaultBillingSpaceFilter });

  const [triggerGetSpaceClusters] = useLazyGetSpacesQuery();
  const [triggerGetNotifications] = useLazyGetNotificationsQuery();

  const unreadNotificationCount = useSelector(countUnreadNotifications);
  const [triggerGetUnreadNotificationsCount] =
    useLazyGetUnreadNotificationsCountQuery();

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        if (res.spaceClusters.length > 0) {
          const localStorageSpaceClusterId = localStorage.getItem(
            "billingSpaceClusterId"
          );

          setSelectedSpaceCluster(
            res.spaceClusters.find(
              (spaceCluster) => spaceCluster.id === localStorageSpaceClusterId
            ) || res.spaceClusters[0]
          );
        } else {
          setSelectedSpaceCluster({ ...defaultBillingSpaceFilter });
        }
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
        setSelectedSpaceCluster({ ...defaultBillingSpaceFilter });
      });
  }, [triggerGetSpaceClusters, billingSpaceFilter.isSpaceClusterListChanged]);

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);

        if (res.spaceClusters.length > 0) {
          const localStorageSpaceClusterId = localStorage.getItem(
            "billingSpaceClusterId"
          );

          setSelectedSpaceCluster(
            res.spaceClusters.find(
              (spaceCluster) =>
                spaceCluster.id ===
                (billingSpaceFilter.newClusterId || localStorageSpaceClusterId)
            ) || res.spaceClusters[0]
          );
        } else {
          setSelectedSpaceCluster({ ...defaultBillingSpaceFilter });
        }
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [billingSpaceFilter.newClusterId]);

  useEffect(() => {
    const { id: clusterId, rootSpace } = selectedSpaceCluster;

    const selectedSpace = {
      id: rootSpace.id,
      clusterId: clusterId,
      name: rootSpace.name,
    };

    const selectedSpaceDetails = {
      spaceClusterId: clusterId,
      spaceId: rootSpace.id,
    };

    if (selectedSpaceCluster.id) {
      localStorage.setItem("billingSpaceClusterId", selectedSpaceCluster.id);
    }

    dispatch(setBillingSpaceFilter({ ...selectedSpaceCluster }));
    dispatch(
      setScheduleFiltersSpaceAndSpaceDetails({
        selectedSpace: { ...rootSpace, clusterId },
        selectedSpaceDetails,
      })
    );
    dispatch(setSelectedFilterSpace(selectedSpace));
    dispatch(setSelectedSmartControllerFilterSpace(selectedSpace));
    dispatch(
      setUsageFilterSelectedSpace({
        ...selectedSpace,
        selectedEntireSpace: rootSpace,
      })
    );
  }, [selectedSpaceCluster]);

  useEffect(() => {
    triggerGetNotifications({
      pageSize: 10,
      pageNumber: 0,
      isFirstTime: true,
      ascending: true,
    });
  }, [triggerGetNotifications]);

  useEffect(() => {
    triggerGetUnreadNotificationsCount();
  }, [triggerGetUnreadNotificationsCount]);

  const getCurrentPageInfo = (path: string) => {
    const page = consumerPrivateRoutes
      .filter((route) => route.root)[0]
      ?.subRoutes?.find((pi) => pi.path === path);

    if (page) {
      return page;
    }

    if (path.startsWith("/dashboard/space-clusters")) {
      const dashboard = consumerPrivateRoutes.find(
        (route) => route.name === "Dashboard"
      );

      if (dashboard && dashboard.subRoutes) {
        const page = dashboard.subRoutes.find((route) => {
          return route.path.startsWith("/dashboard/space-clusters/");
        });

        if (page) {
          return page;
        }
      }
    }

    if (path.startsWith("/dashboard/controllers")) {
      const dashboard = consumerPrivateRoutes.find(
        (route) => route.name === "Dashboard"
      );

      if (dashboard && dashboard.subRoutes) {
        const page = dashboard.subRoutes.find((route) => {
          return route.path.startsWith("/dashboard/controllers/");
        });

        if (page) {
          return page;
        }
      }
    }
  };

  const billingSpaceFilterDropDown = () => {
    return !Object.values(BillingSpaceExcludedPages).includes(
      getCurrentPageInfo(
        pathname
      )?.name.toUpperCase() as BillingSpaceExcludedPages
    ) ? (
      <AppSelect
        icon="apartment"
        selectedValue={{
          value: selectedSpaceCluster?.id,
          label: selectedSpaceCluster?.label,
          data: selectedSpaceCluster,
        }}
        options={spaceClusters.map((spaceCluster) => {
          return {
            value: spaceCluster.id,
            label: spaceCluster.label,
            data: spaceCluster,
          };
        })}
        onChangeOption={(selectedOption) => {
          setSelectedSpaceCluster(selectedOption.data as ISpaceCluster);
        }}
      />
    ) : (
      <></>
    );
  };

  const scrollingExcluded = () => {
    return !Object.values(ScrollingExcludedPages).includes(
      getCurrentPageInfo(pathname)?.name.toUpperCase() as ScrollingExcludedPages
    )
      ? "content-container"
      : "";
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const animateTitleLength = 2;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex === animateTitleLength - 1) clearInterval(interval);
        return nextIndex % animateTitleLength;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={`${styles["main-container"]} vh-100`}>
        <div
          className={`${styles["navbar-container"]} vh-100 d-none d-md-block ${
            collapsed
              ? styles["navbar-container-collapsed"]
              : styles["navbar-container-notCollapsed"]
          }`}
        >
          <SideBar
            collapsed={collapsed}
            routes={consumerPrivateRoutes}
            logos={[oversightLogo, oversightLogoC]}
          />
        </div>
        <div
          className={`${styles["main-content"]} ${
            collapsed
              ? styles["main-content-collapsed"]
              : styles["main-content-notCollapsed"]
          }`}
        >
          <div
            className={`ps-lg-2 py-3  ${styles["header-container"]} ${
              collapsed
                ? styles["main-content-collapsed"]
                : styles["main-content-notCollapsed"]
            }`}
          >
            <Container fluid className={styles["header"]}>
              <Row className="justify-content-between align-items-center">
                <Col xs="auto" className="order-last order-lg-first">
                  <Row className="align-items-center">
                    <Col
                      xs="auto"
                      className="d-flex align-item-center pe-0 d-none d-md-block order-last order-lg-first"
                    >
                      <MaterialIcon
                        icon="menu"
                        onClick={handleCollapse}
                        className={`me-2 ${styles["collapse-icon"]}`}
                        style={{ cursor: "pointer" }}
                      />
                    </Col>
                    <Col xs="auto" className="pe-4 pe-lg-0">
                      <Row className="align-item-center gap-2">
                        {getCurrentPageInfo(pathname)?.name === "Dashboard" ? (
                          <>
                            <Col
                              xs="auto"
                              className={`${styles["title-long"]} p-0`}
                            >
                              <div className={styles[`heading-text-container`]}>
                                <ul
                                  className={`${
                                    styles[`heading-text-list`]
                                  } m-0 p-0`}
                                  style={{
                                    top: `-${currentIndex * 50}px`,
                                  }}
                                >
                                  <li className={styles[`heading-text`]}>
                                    <span
                                      className={styles[`heading-text-color`]}
                                    >
                                      Hello {profile?.firstName},
                                    </span>{" "}
                                    Welcome to Oversight
                                  </li>
                                  <li className={styles[`heading-text`]}>
                                    Dashboard
                                  </li>
                                </ul>
                              </div>
                            </Col>
                          </>
                        ) : (
                          <Col xs="auto" className={`${styles["title"]} p-0`}>
                            <Row className="align-items-center g-2">
                              <Col className="col-auto pe-0 d-none d-md-inline-block">
                                {getCurrentPageInfo(pathname)?.name}
                              </Col>
                              {getCurrentPageInfo(pathname)?.name ===
                                "Usage > Devices" && (
                                <Col>
                                  <div
                                    className={`bg-red ${styles.circle}`}
                                  ></div>
                                </Col>
                              )}
                              {getCurrentPageInfo(pathname)?.name.split(
                                " > "
                              )[1] ? (
                                <Col className="col-auto pe-0 d-md-none">
                                  {
                                    getCurrentPageInfo(pathname)?.name.split(
                                      " > "
                                    )[1]
                                  }
                                </Col>
                              ) : (
                                <Col className="col-auto pe-0 d-md-none">
                                  {getCurrentPageInfo(pathname)?.name}
                                </Col>
                              )}
                            </Row>
                          </Col>
                        )}

                        <Col
                          xs="auto"
                          className="p-0 d-flex d-md-none align-item-center"
                        >
                          <MaterialIcon
                            icon="menu"
                            onClick={() => {
                              setOpen((ps) => !ps);
                            }}
                            className={`me-2 d-md-none ${styles["open-icon"]}`}
                            style={{ cursor: "pointer" }}
                          />
                          <MaterialIcon
                            icon="menu"
                            onClick={handleCollapse}
                            className={`me-2 ${styles["collapse-icon"]} d-none d-md-block`}
                            style={{ cursor: "pointer" }}
                          />
                          <MobileNavBar
                            isOpen={open}
                            closeOpen={() => setOpen((ps) => !ps)}
                            routes={consumerPrivateRoutes}
                            logos={[oversightLogo, oversightLogoC]}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-auto col-xl-7 col-xxl-6">
                  <Row className="align-items-center justify-content-end">
                    <Col className="d-none d-xl-block">
                      {spaceClusters.length > 0 && (
                        <>{billingSpaceFilterDropDown()}</>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      className=" pe-lg-4 me-lg-3 order-first order-md-last"
                    >
                      <Row className="align-items-center">
                        <Col xs="auto" className="order-2 order-md-1 me-1">
                          <div className="position-relative">
                            <MaterialIcon
                              icon="notifications"
                              size={30}
                              onClick={() =>
                                setShowNotifications(!showNotifications)
                              }
                              className="cursor-pointer"
                            />
                            {unreadNotificationCount > 0 ? (
                              <div className={styles.notificationBadge}>
                                {unreadNotificationCount > 9
                                  ? "9+"
                                  : unreadNotificationCount}
                              </div>
                            ) : null}
                            <div
                              className={`${styles.triangle} ${
                                showNotifications && styles.open
                              }`}
                            ></div>
                          </div>
                        </Col>
                        <Col xs="auto" className="px-2 order-1 order-lg-2">
                          <Avatar
                            name={`${profile?.firstName} ${profile?.lastName}`}
                            size="40"
                            round={true}
                            onClick={() => {
                              navigate(AppRoute.USER_PROFILE);
                            }}
                            className="cursor-pointer"
                          />
                        </Col>
                        <Col
                          xs="auto"
                          className="p-0 d-none d-lg-block order-lg-3"
                        >
                          <div
                            className="text-dark-2 font-weight-600 cursor-pointer"
                            onClick={() => {
                              navigate(AppRoute.USER_PROFILE);
                            }}
                          >
                            {profile?.firstName} {profile?.lastName}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-0 mt-md-2">
                <Col xs={"6"} className="d-none d-md-block d-xl-none">
                  {billingSpaceFilterDropDown()}
                </Col>
              </Row>
              <Row>
                <Col className="mt-2 d-block d-md-none">
                  {billingSpaceFilterDropDown()}
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className={`dashboard-scrollable-parent ps-lg-2 pb-4 mt-4 mt-xl-0 ${
              styles[`${scrollingExcluded()}`]
            }            `}
          >
            <Container fluid>
              <ScrollToTop />
              <Row>
                <Col xs={12} className={styles["content"]}>
                  <ErrorBoundary key={pathname}>
                    <Outlet />
                  </ErrorBoundary>
                </Col>
              </Row>
            </Container>
          </div>
          <NotificationsPanel showNotifications={showNotifications} />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
